import { useQuery } from 'utils/react-query';
import { queryUniversalSearch, queryUniversalSearchResults } from 'utils/API';
import { TP, ORGANIZATION_TYPE_IDS, BASE_PAGE_ROUTES, URL_STATIC_PATHS } from 'constants/index';
import { search_types, ADVANCED_SEARCH_TABS_NAMES, ADVANCED_SEARCH_TABS_NAMES_LABELS } from 'constants/search';

export const convertResultsData = rawResults =>
  rawResults.data.reduce(
    (acc, val) => {
      if (!val.entity) return acc;
      return {
        ...acc,
        total: acc.total + val.total,
        types: [...acc.types, val.entity],
        [val.entity]: { count: val.total, items: val.results },
      };
    },
    { types: [], total: 0 },
  );

export const useUniversalSearch = (query, queryConfig = {}) =>
  useQuery({
    queryKey: ['GET_UNIVERSAL_SEARCH', query],
    queryFn: async () => queryUniversalSearch(query),
    ...queryConfig,
  });

export const checkIfSearchQueryIsValid = query => query && query.length > 2;

export const useUniversalSearchResults = (query, queryConfig = {}) =>
  useQuery({
    queryKey: ['GET_UNIVERSAL_SEARCH_RESULTS', query],
    queryFn: async () => queryUniversalSearchResults(query),
    ...queryConfig,
  });

export const processTypeaheadSearch = (
  suggestionsData,
  isSuggestionsLoading,
  queryData,
  isQueryLoading,
  isFilterApplied,
) => {
  const total = queryData?.data?.data?.reduce((prev, cur) => prev + cur.total, 0) || 0;

  if (isSuggestionsLoading || isQueryLoading) {
    return { isLoading: true, suggestions: [], resultGroups: [], total };
  }

  const suggestions = total > 3 && (suggestionsData?.data || []) ? (suggestionsData?.data || []).slice(0, 4) : [];
  const resultGroups = (queryData?.data?.data || []).reduce((acc, resGroup) => {
    if (resGroup.entity === 'organizations') {
      const results = resGroup.results || [];

      const { festivals, companies } = results?.reduce(
        (groupedOrgs, org) => {
          if (org?.organizationType?.id === ORGANIZATION_TYPE_IDS.FESTIVAL) {
            groupedOrgs.festivals.push(org);
          } else if (org?.organizationType?.id === ORGANIZATION_TYPE_IDS.COMPANY) {
            groupedOrgs.companies.push(org);
          }

          return groupedOrgs;
        },
        { festivals: [], companies: [] },
      );

      if (festivals?.length > 0) {
        acc.push({
          ...resGroup,
          entity: 'festivals',
          results: isFilterApplied ? festivals || [] : (festivals || []).slice(0, 3),
        });
      }

      if (companies?.length > 0) {
        acc.push({
          ...resGroup,
          entity: 'companies',
          results: isFilterApplied ? companies || [] : (companies || []).slice(0, 3),
        });
      }
    } else {
      acc.push({
        ...resGroup,
        results: isFilterApplied ? resGroup.results || [] : (resGroup.results || []).slice(0, 3),
      });
    }

    return acc;
  }, []);

  return { isLoading: false, suggestions, resultGroups, total };
};

export const section_label_map = {
  All: 'operabase.general.FN_FILTER_ALL',
  productions: 'operabase.general.m_PERF',
  company: 'operabase.general.m_OPCOS',
  festival: 'operabase.general.m_FESTIVALS',
  profiles: 'operabase.general.m_ARTISTS',
  agencies: 'operabase.general.m_MANAGERS',
  venues: 'operabase.general.m_VENUES',
  works: 'operabase.general.FN_MUSICAL_WORKS_SECTION_NAME',
  videos: 'operabase.general.OPERA_ONLINE',
};

export const isUpdateEntityPage = (router, activeProfile) => {
  const path = router.asPath.split('/');
  return path?.[path?.length - 2]?.includes(activeProfile?.id) || false;
};

export const pathnameToFilterType = pathname => {
  if (pathname === '/search') {
    return search_types.ALL;
  }
  if (pathname === '/productions') {
    return search_types.PRODUCTIONS;
  }
  if (['/artists', '/artists/search'].includes(pathname)) {
    return search_types.PROFILES;
  }
  if (['/companies/[[...action]]'].includes(pathname)) {
    return search_types.COMPANIES;
  }
  if (['/venues/search', '/venues'].includes(pathname)) {
    return search_types.VENUES;
  }
  if (['/managers/search', '/managers'].includes(pathname)) {
    return search_types.AGENCIES;
  }
  if (['/works', '/works/search'].includes(pathname)) {
    return search_types.WORKS;
  }
  if (['/festivals/[[...action]]'].includes(pathname)) {
    return search_types.FESTIVALS;
  }
  if (['/videos/search', '/videos'].includes(pathname)) {
    return search_types.OPERA_ONLINE;
  }

  return search_types.ALL;
};

export const section_search_base_path_map = {
  profiles: BASE_PAGE_ROUTES.ARTISTS,
  productions: BASE_PAGE_ROUTES.PRODUCTIONS,
  company: BASE_PAGE_ROUTES.COMPANIES,
  venues: BASE_PAGE_ROUTES.VENUES,
  agencies: BASE_PAGE_ROUTES.MANAGERS,
  works: BASE_PAGE_ROUTES.WORKS,
  festival: BASE_PAGE_ROUTES.FESTIVALS,
  videos: BASE_PAGE_ROUTES.VIDEOS,
  composers: BASE_PAGE_ROUTES.COMPOSERS,
  All: BASE_PAGE_ROUTES.SEARCH,
  unknown: BASE_PAGE_ROUTES.SEARCH,
};

// TODO: Junaid to handle this in single function to generate urls
export const getSectionLinkGenFn = type => {
  const baseRoute = section_search_base_path_map[type] || section_search_base_path_map.unknown;
  const path = [
    BASE_PAGE_ROUTES.ARTISTS,
    BASE_PAGE_ROUTES.PRODUCTIONS,
    BASE_PAGE_ROUTES.WORKS,
    BASE_PAGE_ROUTES.SEARCH,
  ].includes(baseRoute)
    ? null
    : URL_STATIC_PATHS.SEARCH;

  return query => ({
    baseRoute,
    path,
    shallow: false,
    onlyLinkProps: true,
    ...(query ? { queryParams: { query } } : {}),
  });
};

export const getPlaceHolderText = t =>
  `${t(`${TP}.SEARCH`)}: ${Object.keys(ADVANCED_SEARCH_TABS_NAMES)
    .map(item => {
      if (ADVANCED_SEARCH_TABS_NAMES[item] !== ADVANCED_SEARCH_TABS_NAMES.ALL) {
        return t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES[item]]);
      }
      return '';
    })
    .filter(Boolean)
    .join(', ')}`;
