import React, { useState } from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Image from 'components/uiLibrary/Image';
import usePageContext from 'utils/hooks/usePageContext';

import LogoImage from 'public/images/logo.png';

import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import MenuIcon from '../Shared/Icons/MenuIcon';
import HamburgerMenu from '../../HamburgerMenu';

import classes from './HeaderLeftBlock.module.scss';

const trackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.OPERABASE_BUTTON,
};

const trackingDataHamburger = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.HAMBURGER_MENU,
};

const HeaderLeftBlock = ({ isLoggedIn }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuOpenedOnce, setMobileMenuOpenedOnce] = useState(false);
  const { navigate } = usePageContext();

  const linkProps = navigate.getLinkProps({ onlyLinkProps: true });

  return (
    <div className={classes.block}>
      <button
        type="button"
        className={classes.mobileMenuOpenIcon}
        onClick={() => {
          setMobileMenuOpen(true);
          setMobileMenuOpenedOnce(true);
        }}
      >
        <MenuIcon />
      </button>
      {mobileMenuOpenedOnce && (
        <HamburgerMenu
          isOpen={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          trackingData={trackingDataHamburger}
        />
      )}
      <LinkButton
        {...linkProps}
        variant="tertiary"
        leftIcon={
          <>
            {isLoggedIn && <SpriteIcon className={classes.obIcon} icon="operabase_icon" />}
            <Image
              src={LogoImage}
              alt="Operabase Home"
              title="Operabase Home"
              lazy={false}
              className={classnames(classes.logoImage, { [classes.logoImageLoggedIn]: !isLoggedIn })}
              disableCloudinary
              disableNextImage
            />
          </>
        }
        styles={{
          root: classes.homeLinkButton,
        }}
        isLink
        trackingData={trackingData}
      />
    </div>
  );
};

export default HeaderLeftBlock;
