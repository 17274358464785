import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import isFunction from 'lodash/isFunction';
import usePageContext from 'utils/hooks/usePageContext';
import { sentryCaptureMessage } from 'utils/globals/sentry';
import defaultSEO from '../../utils/seo';
import { removeLocaleFromUrl } from '../../utils/route';

require('dotenv').config();

const baseURL = process.env.FRONTBASE_URL;

const SEO = ({ title, description, ogTitle, ogImages, other, disableQuery, pageQueryList, canonicalPath }) => {
  const { language } = usePageContext();
  const router = useRouter();
  const handleAdditionalLinkTags = useCallback((base, lang, additionalLinkFunctionOrObject) => {
    let additionalLinkTags = [];

    if (isFunction(additionalLinkFunctionOrObject)) {
      additionalLinkTags = additionalLinkFunctionOrObject(`${base}/${lang}`);
    }
    if (Array.isArray(additionalLinkFunctionOrObject)) {
      additionalLinkTags = additionalLinkFunctionOrObject;
    }
    return additionalLinkTags;
  }, []);

  const removeLocaleFromUrlMemoized = useCallback((url, lang) => removeLocaleFromUrl(url, lang), []);

  const defaultSEOMemoized = useCallback(props => defaultSEO(props), []);

  const canonical = useMemo(() => {
    let url = new URL(router.asPath, baseURL);
    if (canonicalPath) {
      url = new URL(canonicalPath, baseURL);
    }

    const pathnameWithoutLocale = removeLocaleFromUrlMemoized(url.pathname, language);
    url.pathname = `${pathnameWithoutLocale}/${language}`;
    const queryKeys = Array.from(url.searchParams.keys());
    const queriesToIgnore = [];

    queryKeys.forEach(key => {
      if (!disableQuery) {
        if (!pageQueryList.includes(key)) {
          queriesToIgnore.push(key);
        }
      } else {
        queriesToIgnore.push(key);
      }
    });

    queriesToIgnore.forEach(key => url.searchParams.delete(key));
    let parsedUrl = url.toString();

    if (parsedUrl && parsedUrl.match(/\/[a-z]{2}\/[a-z]{2}$/)) {
      parsedUrl = parsedUrl.replace(/(.{3})(.{3})$/, '$1');
      sentryCaptureMessage('Canonical URL contains multiple languages', {
        extra: {
          canonical: parsedUrl,
          canonicalBefore: url.toString(),
          asPath: router.asPath,
          language,
          ...(typeof window !== 'undefined' && { href: window.location.href }),
          ...(canonicalPath && { canonicalPath }),
        },
      });
    }

    return parsedUrl;
    // NOTE: Not adding language to dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, canonicalPath, removeLocaleFromUrlMemoized, disableQuery, pageQueryList]);

  const seo = useMemo(
    () => defaultSEOMemoized({ title, description, canonical, locale: language, ogTitle, ogImages }),
    [title, description, canonical, language, ogTitle, ogImages, defaultSEOMemoized],
  );

  if (other) {
    const { additionalLinkTags: additionalLinkFunctionOrObject, ...rest } = other;
    const additionalLinkTags = handleAdditionalLinkTags(baseURL, language, additionalLinkFunctionOrObject);
    return <NextSeo {...seo} {...rest} additionalLinkTags={additionalLinkTags} robotsProps={{ noarchive: true }} />;
  }
  return <NextSeo {...seo} robotsProps={{ noarchive: true }} />;
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ),
  disableQuery: PropTypes.bool,
  pageQueryList: PropTypes.arrayOf(PropTypes.string),
};

SEO.defaultProps = {
  ogImages: [],
  disableQuery: true,
  pageQueryList: [],
};

export default memo(SEO);
